<template>
  <div>
    <div class="table-header">
      <div class="theader-left">
        <span>项目列表</span>
      </div>
      <div class="theader-right">
        <el-col :span="8">
          <div style="display: inline-block">
            <el-input v-model.trim="inputValue" placeholder="请输入项目名称" />
          </div>
        </el-col>
        <el-col :span="16">
          <el-select v-model="value" placeholder="Please input">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-row style="display: inline-block">
            <el-button @click="handleQuery">查询</el-button>
            <el-button @click="handleReset" style="margin-left: 0px"
              >重置</el-button
            >
          </el-row>
        </el-col>
      </div>
    </div>
    <PublicTable ref="publicTable"></PublicTable>
  </div>
</template>

<script>
import table from "@/static/table.json";
//导入表格标题
import LoadData from "@/static/GetFileds/ProList.json";
import PublicTable from "../../components/table/PublicTable.vue";

export default {
  components: { PublicTable },
  data() {
    return {
      inputValue: "", //搜索的内容
      options: [], //选项
      value: "", //当前选项
      url: "", //传给子组件的url
    };
  },
  methods: {
    //搜索
    handleQuery() {
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },
    //重置
    handleReset() {
      this.inputValue = "";
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },
  },
  mounted() {
    this.url = "/api/user/list";
    this.$refs.publicTable.getLoadData(LoadData, this.url, false);
    console.log("LoadData:", LoadData);
    // this.getDataList();
    this.options = table.options;
    this.value = this.options[0].value;

    // console.log(table.table);
  },
};
</script>

<style>

.table-header {
  padding: 10px;
  overflow: hidden;
  /* border: 1px solid red; */
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid ; */
}
.table-header .el-select {
  width: 100px;
  margin: 0 10px 0 10px;
}

</style>